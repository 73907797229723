*,
*::after,
*::before {
  box-sizing: border-box;
}
body {
  background-color: rgb(15, 18, 27);
  background-image: url(./static/img/alien-bg.jpg);
  /* tiled background */
  background-size: 1024px 1024px;
  background-repeat: repeat;
}
.text-shadow-white {
  text-shadow: 0.04em 0.04em 0px rgb(255, 255, 255);
}
.fancy-textarea {
  border: 4px solid black;
  border-radius: 20px;
  min-height: 300px;
}
.rotate {
  animation: rotate 2s infinite linear;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.top-left-image {
  position: absolute;
  top: -40px;
  left: -40px;
  width: 90px;
}
.bottom-right-image {
  position: absolute;
  bottom: -5px;
  right: -20px;
  width: 90px;
  transform: /* reflect horizontal */ scaleX(-1);
}
.bottom-left-image {
  position: absolute;
  bottom: -5px;
  left: -20px;
  width: 90px;
}
.submit-button:disabled {
  background-color: rgb(182, 61, 61);
  animation: wiggle 0.2s infinite linear;
}
@keyframes wiggle {
  0% {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(5px, 5px);
  }
}
.shake {
  animation: randomShake 0.5s infinite linear;
}
@keyframes randomShake {
  0% {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(5px, 5px);
  }
  50% {
    transform: translate(-5px, 0px);
  }
  75% {
    transform: translate(5px, -5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.text-shadow {
  text-shadow: 0.04em 0.04em 0px rgb(27, 255, 23),
    0.04em 0.04em 30px rgb(27, 255, 23), 0.04em 0.04em 30px rgb(27, 255, 23),
    0.04em 0.04em 30px rgb(27, 255, 23), 0.04em 0.04em 30px rgb(27, 255, 23),
    0.04em 0.04em 30px rgb(27, 255, 23), 0.04em 0.04em 30px rgb(27, 255, 23),
    0.04em 0.04em 30px rgb(27, 255, 23), 0.04em 0.04em 30px rgb(27, 255, 23),
    0.04em 0.04em 30px rgb(27, 255, 23), 0.1em 0.1em 2px rgb(27, 255, 23),
    -0.1em 0.1em 2px rgb(27, 255, 23), 0.1em -0.1em 2px rgb(27, 255, 23),
    -0.1em -0.1em 2px rgb(27, 255, 23), 0.1em 0.1em 4px rgb(27, 255, 23),
    -0.1em 0.1em 4px rgb(27, 255, 23), 0.1em -0.1em 4px rgb(27, 255, 23),
    -0.1em -0.1em 4px rgb(27, 255, 23);
}
